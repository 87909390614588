export const apiImport = "/1.0/crm/contact/import"
export const apiAssigned = '/1.0/crm/contact/assigned'
export const apiListPersonnel = '/1.0/crm/contact/listPersonnel'
export const apiExportOrder = '/1.0/crm/order/export'
export const apiTotalPrice = '/1.0/crm/order/totalPrice'
export const apiOrderUpdateStatus = '/1.0/crm/order/updateStatus'
export const apiCallLog = '/1.0/crm/contact/callLog'
export const apiGetProvince = '/1.0/crm/province'
export const apiGetDistrict = '/1.0/crm/district'
export const apiGetWard = '/1.0/crm/ward'
export const apiGetStatistics = '/1.0/crm/dashboard/index'
export const apiGetGrowthStatistics = '/1.0/crm/dashboard/growthStatistics'
export const apiGetRolePermissions = id => `/1.0/crm/permission/${id}/index`
export const apiGetAllPermission = `/1.0/crm/permission/all`
export const apiUpdateStatusOrderVTP = `/1.0/crm/viettel/update`
export const apiAssignPermissions = id => `/1.0/crm/permission/${id}/assignPermissions`
export const apiSalary = '/1.0/personnel/salary/list'
export const apiGetTopSales = '/1.0/crm/dashboard/topSalesChart'
export const apiCreateSalaryConfig = '/1.0/personnel/salary-setting/create'
export const apiUpdateSalaryConfig = id => `/1.0/personnel/salary-setting/${id}/update`
export const apiGetSalaryConfig = '/1.0/personnel/salary-setting/show'
export const apiGetListSalaryConfig = '/1.0/personnel/salary-setting/list'
export const apiGetDetailSalaryConfig = id => `/1.0/personnel/salary-setting/${id}/detail`


